import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-sm.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CheckMark } from '../../../../assets/icons/CheckCircle.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/circlePlus.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
import Flutterwave from '../../../../assets/icons/Flutterwave.svg';
import Monify from '../../../../assets/icons/monify.svg';
import Paystack from '../../../../assets/icons/paystack.svg';
// import Remita from '../../../../assets/icons/Remita.svg';
import { ReactComponent as ThreeDots } from '../../../../assets/icons/three-dot.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import PaymentGatewayForm from '../../../../components/forms/payment-gateway-form';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { deleteBusinessAccount, getAllPaymentGateways } from '../../../../redux/bursary/actions';

const Card = tw.div`
    bg-white
    py-[3.5rem]
    px-[3.2rem]
    w-full
    // mb-[2.4rem]
    no-underline
    rounded-[1.6rem]
`;

const BusinessCard = tw.div`
    bg-[#F9FAFB]
    py-[3.5rem]
    px-[3.2rem]
    w-full
    // mb-[2.4rem]
    no-underline
    rounded-[1.6rem]
`;

const PaymentGateway = [
    { name: 'Paystack', src: Paystack, enabled: false },
    { name: 'Monnify', src: Monify, enabled: false },
    { name: 'Flutterwave', src: Flutterwave, enabled: false },
    // { name: 'Remita', src: Remita, enabled: false },
];

const ReceivingAccounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [gateway, setGateway] = useState('');
    const [activePayments, setActivePayments] = useState([]);
    const [refetchGateways, setRefetchGateways] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openActions, setOpenActions] = useState(null);
    const [businessDetails, setBusinessDetails] = useState(null);
    const [selectedName, setSelectedName] = useState('');

    const providersToUpdateMap = new Map(
        activePayments?.map((provider) => [provider.provider.toLowerCase(), provider]),
    );

    const updatedPaymentGateway = PaymentGateway.map((provider) => ({
        ...provider,
        enabled: providersToUpdateMap.has(provider.name.toLowerCase()),
        id: providersToUpdateMap.get(provider.name.toLowerCase())?._id || null,
        keys: providersToUpdateMap.get(provider.name.toLowerCase())?.keys || null,
    }));

    useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllPaymentGateways());
            setActivePayments(data);
        };

        getActiveGateways();
    }, [dispatch, refetchGateways]);

    const handleShowModal = (gateway) => {
        setGateway(gateway);
        setShowModal(true);
    };

    const handleShowBusiness = (id) => {
        if (openDropdown === id) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(id);
        }
    };

    const handleActions = (action, details, gateway) => {
        if (action === 'edit') {
            setBusinessDetails(details);
            handleShowModal(gateway);
        } else {
            setBusinessDetails(details);
            setGateway(gateway);
            setOpenDropdown('delete');
        }
    };

    const onConfirmDelete = async () => {
        const res = await dispatch(deleteBusinessAccount(gateway?.id, businessDetails?._id));
        if (res) {
            setGateway(null);
            setBusinessDetails(null);
            setRefetchGateways(!refetchGateways);
            setOpenDropdown(null);
            setSelectedName('');
        }
    };

    return (
        <div>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[1rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Settings
                    </PageTitle>
                    <Text weight="500" size="1.4rem">
                        /
                    </Text>
                    <Text weight="400" size="1.6rem">
                        Payment Gateway
                    </Text>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            <PageTitle align="left" size="2.3rem" color="#1F2937" weight="600" top="3rem" bottom="3rem">
                Payment Gateway
            </PageTitle>

            <div className="flex flex-col gap-[1.6rem]">
                {updatedPaymentGateway?.map((gateway, index) => (
                    <>
                        <Card key={index}>
                            <div className="flex items-center justify-between">
                                <img
                                    src={gateway.src}
                                    alt=""
                                    loading="lazy"
                                    className={`${gateway?.keys?.length > 0 ? '' : 'opacity-50'} w-[100px]`}
                                />
                                <FlexDiv className="gap-[3.2rem] items-center">
                                    <Button
                                        onClick={() => handleShowModal(gateway)}
                                        border="0.5px solid #E5E7EB"
                                        className="flex items-center gap-[0.8rem] w-[15.5rem]"
                                    >
                                        <AddIcon fill="#1F2937" />{' '}
                                        <Text size="1.4rem" color="#1F2937" weight="600">
                                            Add Business
                                        </Text>
                                    </Button>
                                    <ArrowIcon
                                        onClick={() => handleShowBusiness(gateway?.id)}
                                        className={`${
                                            openDropdown === gateway?.id ? 'rotate-90' : 'rotate-0'
                                        } cursor-pointer transform duration-100`}
                                    />
                                </FlexDiv>
                            </div>
                        </Card>
                        {openDropdown === gateway?.id && (
                            <FlexColumn className="gap-[0.8rem]">
                                {gateway?.keys?.map((key) => (
                                    <BusinessCard>
                                        <div className="flex items-center justify-between">
                                            <Text weight="600" color="#1F2937">
                                                {key?.businessName}
                                            </Text>
                                            <FlexDiv className="gap-[3.2rem]">
                                                <FlexDiv className="gap-[0.8rem] items-center">
                                                    {key?.active ? (
                                                        <CheckMark fill="#34D399" />
                                                    ) : (
                                                        <CheckMark fill="#D1D5DB" />
                                                    )}
                                                    <Text
                                                        color={key?.active ? '#059669' : '#D1D5DB'}
                                                        align="left"
                                                        size="1.4rem"
                                                    >
                                                        {key?.active === true ? 'Enabled' : 'Disabled'}
                                                    </Text>
                                                </FlexDiv>
                                                <RelativeContainer>
                                                    <ThreeDots onClick={() => setOpenActions(key?._id)} />
                                                    <ActionsPopup
                                                        open={openActions === key?._id}
                                                        close={() => setOpenActions(null)}
                                                        items={[
                                                            {
                                                                icon: <EditIcon />,
                                                                name: 'Edit',
                                                                click: () => {
                                                                    handleActions('edit', key, gateway);
                                                                },
                                                            },
                                                            {
                                                                icon: <DeleteIcon />,
                                                                name: 'Delete',
                                                                click: () => {
                                                                    handleActions('delete', key, gateway);
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </RelativeContainer>
                                            </FlexDiv>
                                        </div>
                                    </BusinessCard>
                                ))}
                            </FlexColumn>
                        )}
                    </>
                ))}
            </div>

            <PaymentGatewayForm
                show={showModal}
                onClose={() => {
                    setBusinessDetails(null);
                    setShowModal(false);
                }}
                gatewayName={gateway?.name}
                gateway={businessDetails}
                setGateway={setBusinessDetails}
                refetch={() => setRefetchGateways((prev) => !prev)}
            />

            <ConfirmActionDialogue
                show={openDropdown === 'delete'}
                close={() => {
                    setSelectedName('');
                    setOpenDropdown(null);
                }}
                title="Delete this Business Account?"
                subtitle="This account alongside its contents will be deleted totally."
                borderLine
                bodyText="To confirm deletion, enter the 'delete' in the text field below."
                input
                noStyle
                value={selectedName}
                onChange={(e) => setSelectedName(e.target.value)}
                btn2Text="Yes, Delete"
                bgColor={'#EF4444'}
                confirmAction={onConfirmDelete}
                btnDisabled={selectedName?.toLowerCase() !== 'delete'}
            />
        </div>
    );
};

export default ReceivingAccounts;
